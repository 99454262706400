import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#CCF4FE',
  light: '#68CDF9',
  main: '#078DEE',
  dark: '#0351AB',
  darker: '#012972',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
};

const GOLDEN = {
  lighter: '#FCF7D7',
  light: '#F2DE86',
  main: '#D4AF37',
  dark: '#98751B',
  darker: '#65470A',
  contrastText: '#FFFFFF',
}

const SCORE = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
}

const SUCCESS_EL = {
  lighter: '#D4CFFC',
  light: '#7B6FEF',
  main: '#2416CB',
  dark: '#130B92',
  darker: '#080461',
  contrastText: '#FFFFFF',
}

const FUN_PICTURE_EL = {
  lighter: '#c1c6e7',
  light: '#707ec7',
  main: '#2f46af',
  dark: '#1f359a',
  darker: '#00197a',
  contrastText: '#FFFFFF',
} /* {
  lighter: '#fcc0e0',
  light: '#ff69b4',
  main: '#ff1b8b',
  dark: '#d81a7f',
  darker: '#991970',
  contrastText: '#FFFFFF',
} */

const INSPIRATION_EL = {
  lighter: '#d6c3f4',
  light: '#a06fe6',
  main: '#7326da',
  dark: '#5816cb',
  darker: '#2a00bc',
  contrastText: '#FFFFFF',
} /* {
  lighter: '#BBDEFB',
  light: '#64B5F6',
  main: '#2196F3',
  dark: '#1976D2',
  darker: '#0D47A1',
  contrastText: '#FFFFFF',
} */

const DISCUSSION_EL ={
  lighter: '#b4ecf6',
  light: '#52d2ea',
  main: '#1abfde',
  dark: '#109aaf',
  darker: '#03636b',
  contrastText: '#FFFFFF',
} /* {
  lighter: '#D1C4E9',
  light: '#9575CD',
  main: '#673AB7',
  dark: '#512DA8',
  darker: '#311B92',
  contrastText: '#FFFFFF',
} */

const INNOVATION_EL = {
  lighter: '#ebdaff',
  light: '#d3a5ff',
  main: '#bc76fa',
  dark: '#a364e6',
  darker: '#8650cf',
  contrastText: '#FFFFFF',
}

const CHALLENGE_EL = {
  lighter: '#b2e8f5',
  light: '#50c9e5',
  main: '#20b3d5',
  dark: '#148fa8',
  darker: '#065963',
  contrastText: '#FFFFFF',
} /* {
  lighter: '#C5CAE9',
  light: '#7986CB',
  main: '#3F51B5',
  dark: '#303F9F',
  darker: '#1A237E',
  contrastText: '#FFFFFF',
} */

const TESTIMONIAL_EL = {
  lighter: '#B2DFDB',
  light: '#4DB6AC',
  main: '#009688',
  dark: '#00796B',
  darker: '#004D40',
  contrastText: '#FFFFFF',
}

const GROUP_IMAGE_EL = {
  lighter: '#C5CAE9',
  light: '#7986CB',
  main: '#3F51B5',
  dark: '#303F9F',
  darker: '#1A237E',
  contrastText: '#FFFFFF',
}  /* {
  lighter: '#ffadfe',
  light: '#f300f5',
  main: '#d200d8',
  dark: '#a800d1',
  darker: '#6500c4',
  contrastText: '#FFFFFF',
} */


const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const STATUS_UNDERGOING = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
}

const STATUS_ACTIVE= {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
}

const STATUS_UNACTIVE= {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
}

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  golden: GOLDEN,
  score: SCORE,
  game: SCORE,

  success_el: SUCCESS_EL,
  fun_picture_el: FUN_PICTURE_EL,
  inspiration_el: INSPIRATION_EL,
  discussion_el: DISCUSSION_EL,
  innovation_el: INNOVATION_EL,
  challenge_el: CHALLENGE_EL,
  testimonial_el: TESTIMONIAL_EL,
  group_image_el: GROUP_IMAGE_EL,


  status_undergoing: STATUS_UNDERGOING,
  status_active: STATUS_ACTIVE,
  status_unactive: STATUS_UNACTIVE,
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
